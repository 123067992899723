import { Vue, Component, Ref, Prop } from 'vue-property-decorator'
import UIcon from '@userbot/components/dist/u-icon'
/* eslint @typescript-eslint/no-var-requires: "off" */
const Buffer = require('buffer/').Buffer
@Component({
  components: {
    UIcon
  }
})
export default class USelectFile extends Vue {
  @Ref()
  input!: HTMLInputElement

  file: File | null = null

  typeFilesAccepted = [
    'image/png',
    'image/jpeg',
    'image/gif'
  ]

  maxSize = 6000000

  get filename () {
    return this.file?.name
  }

  onInputChange () {
    this.file = this.input.files?.length ? this.input.files[0] : null

    if (!this.file) {
      return
    }

    if (this.typeFilesAccepted.includes(this.file.type)) {
      if (this.file.size <= this.maxSize) {
        const reader = new FileReader()
        reader.addEventListener('load', () => {
          this.$emit('updateFile', {
            filename: this.filename,
            fileUrl: URL.createObjectURL(this.file!),
            file: reader.result,
            mime: this.file?.type
          })
        })
        reader.readAsArrayBuffer(this.file!)
      } else {
        this.$notice.alert('Il file che hai cercato di caricare supera il limite massimo consentito di 6 MB.')
        this.file = null
      }
    } else {
      this.$notice.alert('Formato file non supportato')
      this.file = null
    }
  }

  onRemoveFile () {
    this.file = null
  }
}
