import { Vue, Component } from 'vue-property-decorator'
import ULoader from '../u-loader/index.vue'

@Component({
  components: {
    ULoader
  }
})
export default class PageLoader extends Vue {

}
