import { render, staticRenderFns } from "./bg-radial-screw.html?vue&type=template&id=58fa6da3&scoped=true&"
import script from "./bg-radial-screw.ts?vue&type=script&lang=ts&"
export * from "./bg-radial-screw.ts?vue&type=script&lang=ts&"
import style0 from "./bg-radial-screw.scss?vue&type=style&index=0&id=58fa6da3&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "58fa6da3",
  null
  
)

export default component.exports