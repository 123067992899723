import { render, staticRenderFns } from "./u-select-file.html?vue&type=template&id=1be046f5&scoped=true&"
import script from "./u-select-file.ts?vue&type=script&lang=ts&"
export * from "./u-select-file.ts?vue&type=script&lang=ts&"
import style0 from "./u-select-file.scss?vue&type=style&index=0&id=1be046f5&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1be046f5",
  null
  
)

export default component.exports