import { render, staticRenderFns } from "./preview-chat.html?vue&type=template&id=5f8a2edf&scoped=true&"
import script from "./preview-chat.ts?vue&type=script&lang=ts&"
export * from "./preview-chat.ts?vue&type=script&lang=ts&"
import style0 from "./preview-chat.scss?vue&type=style&index=0&id=5f8a2edf&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5f8a2edf",
  null
  
)

export default component.exports