
import { Component, Vue } from 'vue-property-decorator'
import Home from './components/home/index.vue'
import PreviewChat from './components/preview-chat/index.vue'
import UNotice from './components/u-notice/index.vue'

const queryUrl = window.location.search
const urlParams = new URLSearchParams(queryUrl)

@Component({
  components: {
    Home,
    PreviewChat,
    UNotice
  }
})
export default class App extends Vue {
  pageActive: 'home' | 'preview' = 'home'
  data = {
    secretKey: urlParams.get('secretKey') || localStorage.getItem('secretKey') || '',
    customerToken: urlParams.get('customerToken') || localStorage.getItem('customerToken') || '',
    background: urlParams.get('background') || localStorage.getItem('background') || '',
    mode: urlParams.get('mode') || localStorage.getItem('mode') || 'url',
    showPreviewMessage: urlParams.get('showPreviewMessage') === 'true' || localStorage.getItem('showPreviewMessage') === 'true' || false,
    chatOpen: urlParams.get('chatOpen') === 'true' || localStorage.getItem('chatOpen') === 'true' || false,
    sourcecode: atob(urlParams.get('sourcecode') || '') || localStorage.getItem('sourcecode') || '',
    avatar: urlParams.get('avatar') === 'true' || localStorage.getItem('avatar') === 'true' || false
  }

  mounted () {
    if (urlParams.get('demo') === 'true') {
      this.pageActive = 'preview'
    }
  }

  showChat (e: any) {
    this.pageActive = 'preview'
    this.data = e
    localStorage.setItem('secretKey', this.data.secretKey)
    localStorage.setItem('customerToken', this.data.customerToken)
    localStorage.setItem('showPreviewMessage', this.data.showPreviewMessage.toString())
    localStorage.setItem('chatOpen', this.data.chatOpen.toString())
    localStorage.setItem('sourcecode', this.data.sourcecode)
    localStorage.setItem('mode', this.data.mode)
    localStorage.setItem('avatar', this.data.avatar.toString())
    if (this.data.mode !== 'screen') {
      localStorage.setItem('background', this.data.background)
    } else {
      localStorage.removeItem('background')
    }
  }

  showConfiguration () {
    this.pageActive = 'home'
  }
}
