import Vue from 'vue'
import App from './App.vue'
import store from './store'
import FloatingVue from 'floating-vue'
import 'floating-vue/dist/style.css'

Vue.config.productionTip = false
Vue.use(FloatingVue)

new Vue({
  store,
  render: h => h(App)
}).$mount('#app')
