import RadialProgressBar from 'vue-radial-progress'
import Notice from './models/Notice'
import Vue from 'vue'
import UIcon from '@userbot/components/dist/u-icon'

const counter = 0

export default {
  name: 'u-notice',
  components: {
    RadialProgressBar,
    UIcon
  },
  props: {
    widescreen: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  data () {
    return {
      messages: [],
      type: null
    }
  },
  computed: {
    visibileMessages () {
      return this.messages.filter(m => !m.get('dismissed'))
    }
  },
  beforeCreate () {
    Vue.prototype.$notice = this
  },
  methods: {
    /**
     * @description dismiss alert message
     */
    dismiss (i) {
      this.messages.splice(i, 1)
    },

    /**
     * @description notice
     * @param props
     * @return {*|void}
     */
    notice (props) {
      this.type = 'alert-info'
      return this.addNotice(new Notice(props))
    },

    /**
     * @description notice a new chat message
     * @param props
     */
    chat (props) {
      this.type = 'chat'
      props.icon = 'notification-2'

      return this.addNotice(new Notice(props))
    },

    /**
     * @description add success notice
     * @param props
     * @return {*|void}
     */
    success (props) {
      this.type = 'check'
      if (typeof props === 'string') {
        props = { content: props }
      }
      return this.addNotice(new Notice(props))
    },

    /**
     * @description add alert notice
     * @param props
     * @return {*|void}
     */
    alert (props) {
      this.type = 'alert'
      if (typeof props === 'string') {
        props = { content: props }
      }
      return this.addNotice(new Notice(props))
    },

    /**
     * @description add a new message
     * @param message
     */
    addNotice (message) {
      this.messages.unshift(message)

      if (message.isTimed()) {
        this.$nextTick(() => {
          message.countdown()
        })
      }

      return message
    },

    /**
     * @description get radial start color
     * @return {*|string}
     */
    getRadialStartColor () {
      switch (this.type) {
        case 'alert':
          return '#EF3600'

        case 'check':
          return '#00A6CA'

        case 'chat':
          return '#00A6CA'

        default:
          return '#B6B0E5'
      }
    },

    /**
     * @description get radial stop color
     * @return {*|string}
     */
    getRadialStopColor () {
      return this.getRadialStartColor()
    },

    /**
     * @description get radial inner color
     * @return {string}
     */
    getRadialInnerColor () {
      switch (this.type) {
        case 'alert':
          return '#FAC2B2'

        case 'check':
          return '#B2E4EF'

        case 'chat':
          return '#B2E4EF'

        default:
          return '#E5E2FF'
      }
    },

    /**
     * @description get content
     * @param message
     */
    getContent (message) {
      if (message) {
        if (message.get('trim')) {
          const maxLength = 50
          if (message.get('content').length > maxLength) {
            let trimmedString = message.get('content').substr(0, maxLength)
            trimmedString = trimmedString.substr(
              0,
              Math.min(trimmedString.length, trimmedString.lastIndexOf(' '))
            )
            return `${trimmedString}...`
          }
        }
        return message.get('content')
      }
      return ''
    },

    getIcon (message) {
      return message.get('icon')
        ? `${process.env.VUE_APP_IMAGES_URL}${message.get('icon')}.svg`
        : `${process.env.VUE_APP_IMAGES_URL}${this.type}.svg`
    },

    /**
     * @description is desktop
     */
    isDesktop () {
      return document.body.clientWidth >= 1367
    },

    sendChatIfPageNotificationIsEnabled (chat, { name, content, badge }) {
      if (document.getElementById('app').dataset.notificationChat === 'true') {
        this.chat({
          title: this.$t('name-wrote-you', { name }),
          content,
          badge,
          trim: true
        })
      }
    }
  }
}
