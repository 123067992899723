import { Vue, Component, Prop, Emit, Watch } from 'vue-property-decorator'
import UModal from '@userbot/components/dist/u-modal'
import USwitch from '@userbot/components/dist/u-switch'
import CodeEditor from './code-editor/index.vue'
import UButton from '@userbot/components/dist/u-button'

@Component({
  components: {
    UModal,
    USwitch,
    CodeEditor,
    UButton
  }
})
export default class ModalOptions extends Vue {
  @Prop({ type: Boolean, required: false, default: false })
  chatOpen!: boolean

  @Prop({ type: Boolean, required: false, default: false })
  showPreviewMessage!: boolean

  @Prop({ type: Boolean, required: false, default: false })
  isVisibleDigitalHuman!: boolean

  @Prop({ type: String, required: false, default: '' })
  sourcecode!: string

  @Watch('chatOpen', { immediate: true })
  onChatOpenChange (val: boolean, oldVal: boolean) {
    if (val !== oldVal) {
      this.localChatOpen = val
    }
  }

  @Watch('showPreviewMessage', { immediate: true })
  onShowPreviewMessageChange (val: boolean, oldVal: boolean) {
    if (val !== oldVal) {
      this.localShowPreviewMessage = val
    }
  }

  @Watch('isVisibleDigitalHuman', { immediate: true })
  onIsVisibleDigitalHumanChange (val: boolean, oldVal: boolean) {
    if (val !== oldVal) {
      this.localIsVisibleDigitalHuman = val
    }
  }

  @Watch('sourcecode', { immediate: true })
  onSourcecodeChange (val: string, oldVal: string) {
    if (val !== oldVal) {
      this.localSourcecode = val
    }
  }

  localIsVisibleDigitalHuman = false
  localChatOpen = false
  localShowPreviewMessage = true
  localSourcecode = ''

  @Emit('updateOptions')
  updateOptions () {
    (this.$refs.modal as any)!.close()
    return {
      chatOpen: this.localChatOpen,
      showPreviewMessage: this.localShowPreviewMessage,
      isVisibleDigitalHuman: this.localIsVisibleDigitalHuman,
      sourcecode: this.localSourcecode
    }
  }
}
