import { Vue, Component, Ref, Emit, Prop, Watch } from 'vue-property-decorator'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import CodeMirror from 'codemirror'

// import base style
import 'codemirror/lib/codemirror.css'
import 'codemirror/theme/3024-night.css'
// import 'codemirror/addon/lint/lint.css'

import 'codemirror/mode/javascript/javascript.js'
import 'codemirror/addon/display/placeholder.js'
// import 'codemirror/addon/lint/json-lint.js'

@Component
export default class CodeEditor extends Vue {
  @Ref()
  readonly code!: HTMLElement

  @Prop({ type: String, required: false, default: '' })
  value!: string

  @Prop({ type: Boolean, required: false, default: false })
  readonly!: boolean

  codeMirror: any | null
  localValue = {}
  placeholder = `
  window.Userbot({
    key: xxxxxx,
    customerToken: xxx,
    ...
})
  `

  @Watch('value', { immediate: true })
  onValueChange (val: string, oldVal: string) {
    if (val !== oldVal) {
      if (this.codeMirror && val !== this.localValue) {
        this.codeMirror.getDoc().setValue(val)
      }
      this.localValue = val
    }
  }

  @Emit('input')
  onInput (text: any) {
    return text
  }

  mounted () {
    this.codeMirror = CodeMirror(this.code, {
      value: this.localValue,
      mode: 'javascript',
      tabSize: 2,
      readOnly: this.readonly,
      theme: '3024-night',
      lineWrapping: true,
      lineNumbers: true,
      placeholder: this.placeholder.trim()
    })

    this.codeMirror.on('change', (cm: any) => {
      this.localValue = cm.getValue()
      this.onInput(cm.getValue())
    })

    setTimeout(() => {
      this.codeMirror.refresh()
    }, 1)
  }

  refresh () {
    this.codeMirror.refresh()
  }
}
