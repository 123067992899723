import { render, staticRenderFns } from "./configuration-card.html?vue&type=template&id=e80c796a&scoped=true&"
import script from "./configuration-card.ts?vue&type=script&lang=ts&"
export * from "./configuration-card.ts?vue&type=script&lang=ts&"
import style0 from "./configuration-card.scss?vue&type=style&index=0&id=e80c796a&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e80c796a",
  null
  
)

export default component.exports