import { Model } from '../../../core/Model'
import uniqueId from 'lodash/uniqueId'

const defaults = {
  dismissed: false,
  dismissAfter: 4,
  timeElapsed: 0,
  fixed: false
}

export default class Notice extends Model {
  constructor (attrs) {
    attrs.key = uniqueId('u-notice-message-')

    super(Object.assign({}, defaults, attrs))

    this._dismissInterval = null
  }

  /**
     * @description start dismiss countdown
     */
  countdown () {
    this._dismissInterval = setInterval(() => {
      this.increaseTimeElapsed()

      if (this.isDismissable()) {
        this.dismiss()
      }
    }, 1000)
    return this
  }

  /**
     * @description dismiss notice
     */
  dismiss () {
    clearInterval(this._dismissInterval)
    this.set('dismissed', true)
    return this
  }

  /**
     * @description increase time elapsed
     * @return {*}
     */
  increaseTimeElapsed () {
    return this.increase('timeElapsed')
  }

  /**
     * @description check if notice is dismissable
     * @return {boolean}
     */
  isDismissable () {
    return this.get('timeElapsed') === (this.get('dismissAfter') + 1)
  }

  /**
     * @description check if is dismissable notice
     * @return {boolean}
     */
  isTimed () {
    return !this.get('fixed')
  }
}
