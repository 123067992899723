import { Vue, Component, Emit, Prop, Watch } from 'vue-property-decorator'
import UInput from '@userbot/components/dist/u-input'
import UButton from '@userbot/components/dist/u-button'
import USwitch from '@userbot/components/dist/u-switch'
import USelectFile from '../u-select-file/index.vue'
import UIcon from '@userbot/components/dist/u-icon'
import ModalOptions from '@/components/modal-options/index.vue'
/* eslint @typescript-eslint/no-var-requires: "off" */
const Buffer = require('buffer/').Buffer

@Component({
  components: {
    UInput,
    UButton,
    USwitch,
    USelectFile,
    UIcon,
    ModalOptions
  }
})
export default class ConfigurationCard extends Vue {
  @Prop({
    type: Object,
    required: false,
    default: () => {
      return {}
    }
  })
  data!: any

  @Watch('data', { immediate: true })
  onDataChange (val: object, oldVal: object) {
    if (val !== oldVal) {
      this.localData = this.data
    }
  }

  localData: any = {
    secretKey: '',
    customerToken: '',
    background: '',
    mode: 'url',
    avatar: false,
    chatOpen: false,
    showPreviewMessage: false,
    sourcecode: ''
  }

  isVisibleDigitalHuman = false
  modalOptionsStatus = false

  get screenMode () {
    return this.localData.mode === 'screen'
  }

  get IsConfigurationValid () {
    if (this.localData.sourcecode) {
      return !!this.localData.background
    } else {
      return (
        !!this.localData.background &&
        !!this.localData.secretKey &&
        !!this.localData.customerToken
      )
    }
  }

  mounted () {
    if (this.localData.avatar) {
      this.isVisibleDigitalHuman = true
    }
  }

  updateMode (e: boolean) {
    this.localData.background = ''
    this.localData.filename = null
    this.localData.file = {}
    this.localData.mode = e ? 'screen' : 'url'
  }

  updateFile (e: any) {
    this.localData.background = e.fileUrl
    this.localData.filename = e.filename
    this.localData.file = {
      blob: Buffer.from(e.file),
      mime: e.mime
    }
  }

  checkHttps (e: FocusEvent) {
    if (this.localData.mode) {
      if (!this.localData.background.startsWith('http')) {
        this.localData.background = `https://${this.localData.background}`
      }
    }
  }

  @Emit('showChat')
  showChat () {
    return this.localData
  }

  updateOptions (e: {
      chatOpen: boolean
      showPreviewMessage: boolean
      isVisibleDigitalHuman: boolean
      sourcecode: string
    }) {
    this.localData.showPreviewMessage = e.showPreviewMessage
    this.localData.chatOpen = e.chatOpen
    this.localData.sourcecode = e.sourcecode
    this.localData.avatar = e.isVisibleDigitalHuman
    return ''
  }
}
