import { Vue, Component, Emit, Prop } from 'vue-property-decorator'
import BgRadialScrew from '../bg-radial-screw/index.vue'
import ConfigurationCard from '../configuration-card/index.vue'

@Component({
  components: {
    BgRadialScrew,
    ConfigurationCard
  }
})
export default class Home extends Vue {
  @Prop({
    type: Object,
    required: false,
    default: () => {
      return {}
    }
  })
  data!: any

  @Emit('showChat')
  showChat (e: any) {
    return e
  }
}
